.isv-container {
  margin: 30px 0;
  padding: 0 20px;
}
.csv-help__info {
  color: #adadad;
}

.file-icon {
  font-size: 6rem;
  margin: 30px 0 50px;
}
