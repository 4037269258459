.container-form-password {
  width: 28rem;
  background-color: #222225;
  padding: 1.5rem;
  box-shadow: 0px 0px 15px 10px rgb(98, 18, 18);
  border-radius: 5px;
}

.input-password {
  color: #838383;
}

.input-password:focus {
  color: #838383;
}

#button-change-password {
  border-radius: 0;
}

#loading-spinner {
  background-color: red;
  border-inline-color: white;
}

.errorP {
  color: #942424;
  margin: 0;
  padding-top: 10px;
}

.errorP::before {
  display: inline;
  content: '🛡️ ';
}
