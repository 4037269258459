.add-hr__title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 15px;
}

.add-hr__buttons {
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    margin: 0 auto;
}

.label {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    padding: 5px 0;
}