.modal-content {
  border-radius: unset;
  background-color: #1e1e21;
  box-shadow: 0px 0px 15px 10px rgb(98, 18, 18);
}

.modal-header {
  border-radius: unset;
}

#button-next {
  border-radius: 0;
}
