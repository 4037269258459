.navigation {
  background-color: #292a2b;
}

.nav-items {
  background-color: #292a2b;
  color: red;
}

.nav-tabs .nav-link {
  color: #99999a;
  padding: 20px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #f7f7f7;
  background-color: #292a2b;
  box-shadow: rgb(213, 37, 37) 0 4px;
  padding: 20px;
}

.nav-container {
  display: flex;
  background-color: #292a2b;
  justify-content: space-between;
  align-items: center;
  height: 72px;
}

.admin__buttons {
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  border-bottom: 3px solid var(--primary-color);
}

.buttons-csv__help {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  color: white;
  border: 3px solid white;
  border-radius: 50%;
  font-size: 15px;
  font-weight: bold;
  padding: 3px 10px;
  margin-right: 10px;
  transition: 0.2s ease;
}

.buttons-csv__help:hover {
  background-color: white;
  color: var(--secondary-color);
  cursor: pointer;
}

.csv-help {
  background-color: var(--popout-color);
  width: 420px;
  padding: 5px;
  border-radius: 2%;
  position: absolute;
  bottom: -255px;
  right: 0;
  display: none;
  color: white;
  z-index: 1000;
}

.buttons-csv__help:hover + .csv-help {
  display: block;
}

.import-container {
  position: relative;
  display: flex;
  align-items: center;
}
