.dashboard-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.dashboard-main {
  min-height: 100%;
  width: 80%;
  margin: 0 auto;
  flex: 1;
  display: flex;
}
