.filterForm__container {
  background-color: #0a0a0a;
  padding: 20px;
}
.filterForm__header,
.filterForm__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/*--------grades------------*/
.filterForm__grade-container {
  display: flex;
}

/*-----buttons----------*/
.filterForm__btn--dark,
.filterForm__btn--dark:focus {
  background-color: #212529;
  padding: 5px 20px;
}

.filterForm__btn--dark:hover {
  background-color: #424649;
}

.filterForm__btn--cancel {
  padding: 10px 25px;
}
.filterForm__footer {
  justify-content: flex-end;
}
/*-------------checkbox------------*/
.filterForm__checkbox-section {
  margin-top: 20px;
}
.filterForm__checkbox-section--title {
  margin-bottom: 5px;
}
.filterForm__checkbox-section--container {
  display: flex;
}
.filterForm__checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.filterForm__checkbox-container:not(:first-child) {
  margin-left: 10px;
}
.filterForm__checkbox {
  display: none;
  visibility: hidden;
}
.filterForm__checkbox-label {
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  background-color: #292a2b;
  color: #e02735;
  transition: all 0.2s ease-in-out;
}
.filterForm__checkbox--icon {
  margin-left: 5px;
}
.filterForm__checkbox--span {
  color: #f7f7f7;
}
.filterForm__checkbox:checked + .filterForm__checkbox-label {
  color: #f7f7f7;
  background-color: #e02735;
  transition: all 0.1s ease-in-out;
}
/*--------------number input-------------*/
.filterForm__input-field {
  color: #f7f7f7;
  padding: 3px 0 3px 8px;
  background-color: #292a2b;
  border: none;
  max-width: 100px;
}

.filterForm__input-field:focus {
  outline: none;
}

.filterForm__input--span {
  margin: 0 10px 0 20px;
}
/*--------radio input------------*/
.filterForm__radio-input {
  background: red;
  margin-left: 10px;
}

.filterForm__radio-label {
  margin-left: 10px;
}
.filterForm__radio-container {
  margin: 5px 0 8px;
}

/*---------footer--------*/
.filterForm__footer {
  margin-top: 40px;
}
