/*-------------sidebar view -------------*/
.cv-container {
  width: 100%;
}
.cv-sidebar__wrapper {
  width: 250px;
  margin-right: 4px;
}
.cv-sidebar__container {
  width: 100%;
  background-color: #292a2b;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cv-sidebar__avatar {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin: 30px 0 12px;
}

.cv-sidebar__credentials {
  margin-bottom: 30px;
}

.cv-sidebar__names {
  text-align: center;
  font-size: 24px;
}
.cv__link {
  display: flex;
  align-items: center;
}

.cv__link--main {
  padding: 0 26px;
}
.cv__link--main-error {
  color: #4d4d4d;
  padding: 15px 26px;
}
.cv-link__url {
  width: 100%;
  color: #0b8bd4;
  text-align: center;
  text-decoration: none;
  margin: 0 auto;
  transition: all 0.16s ease-in-out;
}
.cv-link__url:hover {
  color: #cfcfcf;
  transition: all 0.16s ease-in-out;
}

.cv-link__url--main {
  font-size: 16px;
  text-align: left;
  padding: 17px 0;
}
.cv-sidebar__icon {
  margin-right: 10px;
}

.cv-sidebar__icon--grey {
  color: #4d4d4d;
}

.cv-sidebar__p {
  margin-right: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.cv-sidebar__bio {
  margin: 20px 22px;
}

.cv-sidebar__bio--title {
  color: #4d4d4d;
  font-weight: bold;
  margin-bottom: 2px;
}
.cv-sidebar__bio--content {
  font-size: 14px;
}
.cv-sidebar__button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cv-sidebar__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto 20px;
  width: 200px;
}

/*-----------main cv view -----------------*/
.cv-main__wrapper {
  flex: 1;
  margin-bottom: 60px;
}
