.wrapper {
  position: relative;
}
.custom-container {
  background-color: #292a2b;
  padding: 10px;
}

.custom-container-third {
  height: 80px;
  background-color: #222224;
  position: relative;
  max-width: 1430px;
}
.list-container {
  padding: 20px 20px 0;
  border-bottom: 3px solid #222224;
}

.accordion-item {
  border-radius: 0 !important;
  border-color: rgb(38, 38, 38);
  --bs-accordion-bg: #1e1e20;
  --bs-accordion-inner-border-radius: 0px;
  --bs-accordion-border-color: #292a2b;
  --bs-accordion-border-radius: 0px;
}

.accordion-header {
  display: flex;
  align-items: center;
  border-radius: 0 !important;
  background-color: #292a2b;
}

.accordion-header--disabled {
  cursor: default;
}

.accordion-header__header {
  flex: 1;
}

.accordion-header__btn-container {
  padding-right: 20px;
}

.spacer {
  flex: 1;
}

.accordion-button {
  color: #f7f7f7;
  background-color: #292a2b !important;
  display: flex;
  justify-content: space-between;
  height: 70px;
}

.accordion-button::after {
  background-image: url('/public/collapse-icon/Group 29.png');
}

.accordion-button:not(.collapsed)::after {
  background-image: url('/public/collapse-icon/Group 29.png');
  margin-left: 10px;
}
.accordion-button:not(.collapsed) {
  color: #f7f7f7;
}
.accordion-button.collapsed::after {
  margin-left: 10px;
}

.accordion-button:focus {
  box-shadow: none;
  border-width: unset;
  border-color: unset;
}

.accordion-body {
  padding: 0;
}

.accordion-body-color {
  background-color: #222324;
}

.row-main {
  color: #adadad;
  height: 70%;
  font: normal normal normal 12px/15px Catamaran;
  text-align: left;
  opacity: 1;
  letter-spacing: 0;
}

.row-second {
  color: #f7f7f7;
  height: 40%;
  text-align: left;
  letter-spacing: 1px;
  line-height: normal;
  opacity: 1;
  font-size: 15px;
}

.row-second-span {
  color: #9e9e9e;
}

.navigation {
  background-color: #292a2b;
}

.nav-tabs {
  --bs-nav-tabs-border-width: unset;
  --bs-nav-tabs-border-radius: 0;
}

.nav-items {
  background-color: #292a2b;
  color: red;
}

.nav-tabs .nav-link {
  color: #99999a;
  padding: 20px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #f7f7f7;
  background-color: #292a2b;
  box-shadow: rgb(213, 37, 37) 0 4px;
  padding: 20px;
}

.icon {
  width: 15px;
  height: 15px;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  background-color: #1e1e20;
  background-image: url('/public/search-icon/magnifier.png');
  background-repeat: no-repeat;
}
.icon-filter {
  margin-right: 10px;
}
.btn-filter {
  padding: 0 10px;
  background-color: #1e1e20;
  color: #b1b2b2;
  border: none;
  border-radius: 0;
}

#form-control-search-hr::placeholder {
  color: #6c757d;
  opacity: 1;
}

#form-control-search-hr {
  padding-left: 33px;
  color: #f7f7f7 !important;
  width: 365px;
  height: 38px;
  border: none;
  border-radius: unset;
  background-color: #1e1e20;
}

#form-control-search-hr:focus {
  border: none;
  box-shadow: rgb(213, 37, 37) 0 2px;
}

.custom-button {
  border-radius: unset;
  font-size: 16px;
}

.custom-button:not(:first-child) {
  margin-left: 20px;
}

.custom-button-disabled {
  border-radius: unset;
  margin-left: 20px;
  font-size: 16px;
  pointer-events: none;
  opacity: 0.4;
}

.card {
  --bs-card-height: 110px;
  --bs-card-inner-border-radius: unset;
  --bs-card-border-radius: unset;
  --bs-card-border-color: #292a2b;
  --bs-card-bg: unset;
  min-width: 150px;
}

.row {
  margin-top: unset;
  margin-right: unset;
  margin-left: unset;
}

/*.custom-button {*/
/*  margin-right: 3rem !important;*/
/*  transform: translateY(-50%) !important;*/
/*  right: 0 !important;*/
/*  top: 50% !important;*/
/*  position: absolute !important;*/
/*}*/

.custom-p {
  height: 4px;
  background-color: #1e1e21;
}

.custom-nav-search {
  background-color: #292a2b;
  display: flex;
  justify-content: space-between;
}

#back-custom-button {
  width: 26px;
  height: 26px;
  background-image: url('/public/back-next-icon/Wróć.png');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: unset;
  border: unset;
  background-color: #cfcfcf;
}
#back-custom-button:focus {
  box-shadow: 0 0 2px 2px rgba(159, 16, 16, 1);
}
#next-custom-button {
  width: 26px;
  height: 26px;
  background-image: url('/public/back-next-icon/Dalej.png');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: unset;
  border: unset;
  background-color: #cfcfcf;
}
#next-custom-button:focus {
  box-shadow: 0 0 2px 2px rgba(159, 16, 16, 1);
}
.col-custom-p {
  font: normal normal normal 16px/27px Catamaran;
  width: 108px;
  height: 27px;
  color: #f7f7f7;
}

.col-custom-select {
  color: #333333;
  width: 31px;
  height: 24px;
  font-size: 12px;
}

.col-custom-padding-count-element {
  width: 70px;
  height: 27px;
  font: normal normal normal 14px/27px Catamaran;
  color: #f7f7f7;
  text-align: center;
}

.col-custom-button-next-back {
  width: 26px;
  height: 26px;
}
.col-custom-select-element {
  color: #333333;
  background-color: white;
  width: 31px;
  height: 24px;
  font-size: 12px;
}
#row-custom-padding-elements {
  position: absolute;
  top: 55%;
  right: 0;
  transform: translateY(-50%);
}

#custom-select-pagination-page {
  color: #333333;
  padding: 0;
  border-radius: 0;
  background-position: right center;
  background-size: 12px 10px;
}

.popover {
  border-radius: unset;
  background-color: #1e1e21;
  color: black;
  position: absolute;
  top: 0px;
  left: 0px;
}

.popover-header {
  border-radius: unset;
}

.popover-arrow {
  position: absolute;
  left: 127px !important;
  transform: unset !important;
}

.popover-custom-button {
  width: 100px;
}

.search-result__warning {
  text-align: center;
  font-size: 16px;
  color: #f7f7f7;
  margin-top: 50px;
}
.search-result__clear-filters {
  color: red;
  cursor: pointer;
}
