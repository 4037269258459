.cv-section__container {
  margin-bottom: 12px;
}
.cv-section__title {
  display: flex;
  padding: 10px 26px;
  align-items: center;
  min-height: 60px;
  background-color: #292a2b;
  font-size: 20px;
  font-weight: 600;
}
.cv-section__content {
  background-color: #222224;
}
