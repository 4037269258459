.admin__users-table {
}

.hrTable {
  margin: 20px;
  display: grid;
  grid-row-gap: 10px;
  background-color: #1e1e1f;
  padding-bottom: 10px;
}

.table-header-cell,
.table-row__max-reserved-students {
  text-align: center;
}

.accordion-header__hr-header {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
}

.accordion-header__hr-header--subtitle {
  flex: 1;
}
.accordion-header__hr-header--subtitle:nth-child(1) {
  max-width: 250px;
}
.accordion-header__hr-header--subtitle:nth-child(3) {
  max-width: 200px;
}

.hrTable__student-container {
  padding: 15px 40px;
  align-items: center;
  display: flex;
  color: #f7f7f7;
  border-bottom: 1px solid #292a2b;
}

.hrTable__student-header {
  flex: 1;
}

.hrTable__student-header--avatar {
  margin-right: 20px;
}
