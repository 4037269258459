.grades-table__table {
  background-color: #222324;
  display: flex;
  flex-wrap: wrap;
}

.grades-table__table-element {
  padding: 15px 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  border: 1px solid #292a2b;
  min-width: 130px;
}
.grades-table__table-element.md {
  padding: 15px 20px;
  flex: 1;
}
.grades-table__table-element.md .grade-row__score {
  display: flex;
  flex-direction: row;
}

.grade__score {
  color: #f7f7f7;
  font-size: 18px;
  margin-bottom: 0;
}
.grade__score--span {
  color: #4d4d4d;
}

.grade__row--score {
  margin: 20px 0 0;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.grade__row--score.lg {
  margin: 10px 0 10px;
  flex-direction: column;
}

.grades-table__table-element:nth-child(2).sm,
.grades-table__table-element:nth-child(3).sm {
  flex: 1.4;
}
.grades-table__table-element:nth-child(9).sm,
.grades-table__table-element:nth-child(4).sm,
.grades-table__table-element:nth-child(6).sm {
  flex: 1.6;
}
.grades-table__table-element:nth-child(1).sm,
.grades-table__table-element:nth-child(7).sm {
  flex: 1;
}

.grades-table__table-element:nth-child(5).sm,
.grades-table__table-element:nth-child(10).sm,
.grades-table__table-element:nth-child(8).sm {
  flex: 1.2;
}

.grade__row--score.lg .grade__score {
  font-size: 24px;
  margin-bottom: 5px;
}

.grade__row--title {
  color: #cfcfcf;
  min-width: 100%;
  flex: 1;
}

.grades-table__table-element.sm .grade__row--title {
  font-size: 12px;
}
.grades-table__table-element.sm .grade__score {
  font-size: 15px;
}
