.container-header-second {
  max-width: 1430px;
  width: 80%;
  padding: 0;
}

.navbar-color {
  background-color: #1e1e1f !important;
}

#collasible-nav-dropdown {
  background-color: #1e1e1f !important;
}

.dropdown-menu {
  background-color: #1e1e1f !important;
  border-radius: unset !important;
  margin-top: 0.7rem !important;
  width: 100%;
}

.dropdown-item:hover {
  background-color: #1e1e1f;
  box-shadow: rgb(213, 37, 37) 0px 4px;
}

@media screen and (max-width: 768px) {
  .dropdown-menu.show {
    margin: unset !important;
  }
}

.avatar {
  border-radius: 50%;
}
