:root {
  /*zmienne*/
  --primary-color: #222225;
  --secondary-color: #292a2b;
  --popout-color: #0a0a0a;
  --button-color: #e02735;
  --button-color-hover: #931922;
  --text-secondary-color: #c4c4c4;
}

html {
  min-height: 100%;
  height: 100%;
  font-family: 'Catamaran', sans-serif;
  color: #f7f7f7;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--primary-color);
  min-height: 100%;
  height: 100%;
  font-family: inherit;
  color: inherit;
}
#root {
  min-height: 100%;
  height: 100%;
}

.background-blur {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #202020;
  opacity: 0.55;
  overflow: hidden;
  z-index: 98;
}

.modal-center {
  display: flex;
  flex-direction: column;
  background-color: var(--popout-color);
  width: 100%;
  color: white;
}
.button {
  padding: 10px;
  background-color: var(--button-color);
  border-radius: 3px;
  transition: 0.2s ease;
  margin: 0 10px;
  color: white;
  border: none;
}

.button:hover {
  cursor: pointer;
  background-color: var(--button-color-hover);
}
.button:focus {
  background-color: var(--button-color);
}
